import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import debounce from 'lodash/debounce';

import Box from './Box';
import Flex from './Flex';
import Input from './Input';
import Image from './Image';

const Opt = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const LabelBox = styled(Flex)`
  position: relative;
  .mark {
    display: block;
    position: absolute;
    top: 50%;
    height: 1em;
    width: 1em;
    &::before {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: ${themeGet('colors.primaryInvert')};
      ${({ type }) => type === 'radio' && `
        border-radius: 50%;
      `}
      border: 0.125em solid ${themeGet('colors.primary')};
    }
    &::after {
      opacity: 0;
      content: "";
      position: absolute;
      display: block;
      ${(props) => {
        const isRadio = props.type === 'radio';
        return isRadio ? `
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          width: 50%;
          height: 50%;
          border-radius: 50%;
          background: ${themeGet('colors.primary')(props)};
        ` : `
          left: 50%;
          top: 10%;
          width: 35%;
          height: 60%;
          border: solid ${themeGet('colors.primary')(props)};
          border-width: 0 2px 2px 0;
          transform: translateX(-50%) rotate(45deg);
        `;
      }};
      transition: opacity ${themeGet('duration')}ms;
    }
  }
  .bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transition: background ${themeGet('duration')}ms;
    background: ${themeGet('colors.primaryInvert')};
  }
  ${({ disabled, readOnly }) => !disabled && !readOnly && `
    &:hover {
      .mark::after {
        opacity: 0.5;
      }
    }
  `}
  input:checked {
    & ~ .bg {
      border-width: 4px;
      border-color: ${themeGet('colors.primary')};
    }
    & ~ span {
      & > .mark {
        &::after {
          opacity: 1;
        }
      }
    }
  }
`;

class Option extends PureComponent {
  constructor(props) {
    super(props);
    this.noLabel = !props.children && !props.label;
    this.state = {
      otherValue: props.otherValue,
    }
    this.formikChange = debounce(this.formikChange, 300)
  }

  handeOtherValue = (e) => {
    const { value } = e.target;
    const { onOtherChange, name } = this.props
    this.setState({ otherValue: value }, () => {
      if (onOtherChange) {
        this.formikChange({ name, value });
      }
    })
  }

  formikChange = (payload) => {
    this.props.onOtherChange(payload)
  }

  render() {
    const {
      children,
      label,
      name,
      value,
      src,
      checked,
      type,
      onChange,
      onBlur,
      disabled,
      spacing,
      special,
      nameId,
      onOtherChange,
      otherValue: ov,
      readOnly,
      ...props
    } = this.props;
    const { otherValue } = this.state;
    const { noLabel } = this;
    return (
      <LabelBox
        is="label"
        type={type}
        disabled={disabled}
        cursor={disabled ? 'not-allowed' : (readOnly ? 'default' : 'pointer')}
        lineHeight="1"
        p={spacing}
        textAlign="left"
        flexDirection="column"
        justifyContent="center"
        readOnly={readOnly}
        {...props}
      >
        <Opt
          name={name}
          value={value}
          checked={checked}
          type={type}
          onChange={onChange}
          disabled={disabled || readOnly}
        />
        <Box.inline
          className="bg"
          border="1px solid"
          borderColor="variations.gray.2"
        />
        {src && <Image src={src} position="relative" />}
        <Box.inline position="relative" display="block" mt={src && spacing}>
          <Box.inline
            className="mark"
            left={noLabel && '50%'}
            transform={noLabel ? 'translate(-50%, -50%)' : 'translateY(-50%)'}
            opacity={disabled ? 0.5 : 1}
          />
          <Flex.inline pl={!noLabel && `calc(${spacing} * 2)`} opacity={disabled ? 0.5 : 1} alignItems="center">
            {children || label}
            {checked && special === 'other' ? (
              <Box pl="0.5em" flex={1} position="relative">
                {readOnly ? otherValue : (
                  <Input value={otherValue} readOnly={readOnly} onChange={this.handeOtherValue} autoFocus p="0.5em" />
                )}
              </Box>
            ) : null}
          </Flex.inline>
        </Box.inline>
      </LabelBox>
    );
  }
}

Option.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  name: PropTypes.string,
  src: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  type: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  spacing: PropTypes.string,
};

Option.defaultProps = {
  type: 'radio',
  spacing: '1em',
};

export default Option;
