import React from 'react'
import styled from 'styled-components';
import {
  space,
  width,
  height,
  opacity,
  position,
  textAlign,
  display,
} from 'styled-system';
import tag from 'clean-tag';

import Link from './Link'
import Box from './Box'
import blacklist from './utils/blacklist';

const Image = styled(tag.img)`
  ${space}
  ${height}
  ${width}
  ${opacity}
  ${position}
  ${textAlign}
  ${display}
`;

Image.defaultProps = {
  blacklist,
  width: 1,
};

Image.displayName = 'Image';

export default ({ href, src, ...props }) => href ? (
  <Box {...props}>
    <Link href={href}><Image src={src} /></Link>
  </Box>
) : <Image src={src} {...props} />
