import React from 'react'
import * as Yup from 'yup'
import { Formik, ErrorMessage } from 'formik'

import Layout from '../containers/Layout'
import Container from '../components/Container'
import Text from '../components/Text'
import Button from '../components/Button'
import Link from '../components/Link'
import VerticalCenter from '../components/VerticalCenter'
import { mobileOrDesktop } from '../components/ThemeProvider/theme'

import Choice from '../components/Survey/Choice'
import Input from '../components/Input'

const validationSchema = Yup.object().shape({
  '2-2': Yup.number().required(),
  '2-3': Yup.string().when('2-2', {
    is: 0,
    then: Yup.string().required()
  })
});

const test = (name, values) => {
  let required;
  try {
    validationSchema.validateSyncAt(name, validationSchema.cast(Object.assign({}, values, { [name]: '' })));
  } catch(e) {
    required = true;
  }
  return required
}

const FormPage = () => (
  <Layout noHeader>
    <VerticalCenter>
      <Container py="2em">
        <Text align="center" fontSize={mobileOrDesktop('1em', '1.5em')}>Hello</Text>
        <Formik
          validationSchema={validationSchema}
        >
          {({ values, handleChange, handleBlur }) => (
            <div>
              <Text>{JSON.stringify(values)}</Text>
              {test('2-3', values)}
              <Choice
                name="2-2"
                options={[
                  { label: 'yes' },
                  { label: 'no' },
                ]}
                onChange={handleChange}
              />
              <ErrorMessage name="2-2">
                {(msg) => (
                  <Text color="warn">{msg}</Text>
                )}
              </ErrorMessage>
              <Input name="2-3" onChange={handleChange} onBlur={handleBlur} />
              <ErrorMessage name="2-3">
                {(msg) => (
                  <Text color="warn">{msg}</Text>
                )}
              </ErrorMessage>
            </div>
          )}
        </Formik>
        <Button is={Link} to="/info"></Button>
      </Container>
    </VerticalCenter>
  </Layout>
);

export default FormPage
