import React, { createElement, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FastField } from 'formik';
import merge from 'lodash/merge';
import { Map } from 'immutable';

import Flex from '../Flex';
import Box from '../Box';
import Image from '../Image';
import Option from '../Option';

import checkImage from '../utils/checkImage';

class Choice extends PureComponent {
  constructor(props) {
    super(props);
    const { options, perRow, multiple } = props;
    this.width = perRow ? String(perRow).split(',').map((r) => r && 1 / r) : 1 / options.length;
    this.Option = multiple ? this.CheckBox : this.Radio;
  }

  state = { isNone: false }

  Radio = ({ opt, index, value, ...props }) => createElement(Option, {
    disabled: this.props.disabled,
    type: 'radio',
    value: index,
    checked: value && value == index,
    ...opt,
    ...props,
  })

  CheckBox = ({ opt, name, index, ...props }) => createElement(FastField, merge({
      name: `${name}[${index}]`,
    }, this.state.isNone ? { none: true } : {}), ({ field }) => {
      let checked = Boolean(field.value);
      if (field.value === 'false') {
        checked = false;
      }
      return createElement(Option, {
        disabled: opt.special === 'none' ? this.props.disabled : this.state.isNone || this.props.disabled,
        type: 'checkbox',
        checked,
        ...props,
        ...field,
        onChange: (e) => {
          if (opt.special === 'none') {
            const isNone = e.target.checked;
            props.onChange({
              target: {
                value: isNone ? Array(index).concat(true) : [],
                name,
              },
              persist: () => {},
            });
            setTimeout(() => this.setState({ isNone }));
          } else {
            field.onChange(e);
          }
        },
        value: index,
        ...opt,
        otherValue: Map.isMap(this.props.others) ? this.props.others.get(field.name) : '',
      });
    }
  )

  render() {
    const {
      options,
      perRow,
      multiple,
      label,
      name,
      disabled,
      required,
      values,
      randomID,
      onChange,
      onBlur,
      value,
      setOtherValue,
      setTimer,
      others,
      src,
      readOnly,
      ...props
    } = this.props;
    return (
      <Box {...props}>
        {src && (
          <Box px="0.5em" mb="1em">
            <Image src={checkImage(src)} />
          </Box>
        )}
        <Flex flexWrap="wrap">
          {options.map((opt, index) => (
            <Box key={index} width={this.width} py="0.25em" px="0.375em">
              {createElement(this.Option, {
                index,
                name,
                opt: {
                  ...opt,
                  src: checkImage(opt.src),
                },
                onChange,
                onBlur,
                value,
                onOtherChange: setOtherValue,
                otherValue: Map.isMap(others) ? others.get(name) : '',
                height: '100%',
                readOnly,
              })}
            </Box>
          ))}
        </Flex>
      </Box>
    );
  }
}

Choice.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    src: PropTypes.string,
  })),
  perRow: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

export default Choice;
